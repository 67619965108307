import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { RootStore } from './RootStore'

export default class ScreenStore {
  rootStore: RootStore
  loading = null
  scoreboard = null
  playerScores = null
  sortField = null
  gender = null
  league = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateScoreboard = (scoreboard) => {
    this.scoreboard = scoreboard
  }
  updatePlayerScores = (playerScores) => {
    this.playerScores = playerScores
  }
  updateLoading = (loading) => {
    this.loading = loading
  }
  updateSortField = (sortField) => {
    this.sortField = sortField
  }
  updateGender = (gender) => {
    this.gender = gender
  }
  updateLeague = (league) => {
    this.league = league
  }

  async getScoreboard(eventUuid: string) {
    this.updateLoading(true)
    const response: any = await Api.getScoreboard(eventUuid, this.sortField, this.gender, this.league)
    if (response.ok) {
      this.updateScoreboard(response.data)
    }
    this.updateLoading(false)
  }

  async startGetScoreboardPolling(eventUuid: string) {
    await this.getScoreboard(eventUuid)
    // Poll with 3 sec interval
    setTimeout(() => this.startGetScoreboardPolling(eventUuid), 2000)
  }

  // Player specific "display"
  async getPlayerScores(eventUuid: string, playerUuid: string, sortField) {
    this.updateLoading(true)
    const response: any = await Api.getPlayerScores(eventUuid, playerUuid, sortField)
    if (response.ok) {
      this.updatePlayerScores(response.data)
    }
    this.updateLoading(false)
  }
}
