import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { useStore } from '../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { AppTheme, Colors } from '../Utils/theme'
import Logo from '../Assets/Images/logo.png'
import { ScoreRow } from '../Components'
import moment from 'moment'
import { Gender, League, MeasurementSystem } from '../Constants'
import { isDefined } from '../Utils/common'

const useStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: Colors.brandPrimary,
    backgroundImage: 'url(/images/background.jpg)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  logoLink: {
    textDecoration: 'none',
  },
  logo: {
    height: '4rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '2rem',
    },
  },
  header: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      padding: '.5rem',
    },
  },
  screenTitle: {
    color: Colors.white,
    fontWeight: 600,
    fontSize: '1.75rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  subHeaderText: {
    fontWeight: 600,
    fontSize: '1rem',
    color: Colors.white,

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem',
    },
  },
  content: {
    padding: '1rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      padding: '.125rem .125rem 1rem',
    },
  },
  flexSpacer: {
    flex: 1,
  },
  divider: {
    height: '2rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '.5rem',
    },
  },
  currentTime: {
    position: 'absolute',
    top: '.5rem',
    left: '35%',
    right: '35%',
    textAlign: 'center',
    color: Colors.white,
    userSelect: 'none',
    fontWeight: 600,
    letterSpacing: '.1rem',
    fontSize: '1.5rem',

    [theme.breakpoints.down('md')]: {
      top: '.25rem',
      fontSize: '1rem',
    },
    // Mobile styles
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '3.75rem',
      left: '27%',
      right: '27%',
    },

    textAlign: 'center',
    color: Colors.white,
    userSelect: 'none',
    fontWeight: 600,
    letterSpacing: '.1rem',
    fontSize: '1.25rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '.85rem',
      paddingTop: '.5rem',
      paddingBottom: '1rem',
    },
    // Mobile styles
    [theme.breakpoints.down('sm')]: {
      fontSize: '.85rem',
    },
  },
  filterInner: {
    background: 'rgba(0, 0, 0, .125)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '.5em',
    overflow: 'hidden',
  },
  filterLeft: {
    cursor: 'pointer',
    padding: '.5em',
    paddingLeft: '1em',
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  filterSpacer: {
    height: '.25rem',
    width: '1rem',
  },
  filterCenter: {
    cursor: 'pointer',
    padding: '.5em',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  filterRight: {
    cursor: 'pointer',
    padding: '.5em',
    paddingLeft: '1rem',
    paddingRight: '1em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  filterBoxActive: {
    background: 'rgba(0, 0, 0, .25)',
  },
  filterTextActive: {
    color: Colors.gold,
  },
  filterText: {},
  headerBorder: {
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    height: '.1875rem',
    background: 'radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 100%)',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: 1,
      marginTop: '.25rem',
      marginLeft: '.25rem',
      marginRight: '.25rem',
    },
  },
}))

const Scoreboard = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { eventUuid } = useParams()

  const { screenStore } = useStore()

  useEffect(() => {
    const search = (window.location.search || '').toLowerCase()

    if (search.includes('female') || search.includes('women')) {
      screenStore.updateGender(Gender.Female)
    } else if (search.includes('male') || search.includes('men')) {
      screenStore.updateGender(Gender.Male)
    }
    if (search.includes('amateur')) {
      screenStore.updateLeague(League.Amateur)
    } else if (search.includes('pro')) {
      screenStore.updateLeague(League.Pro)
    }

    if (eventUuid) screenStore.startGetScoreboardPolling(eventUuid)
  }, [])

  const getIsImperial = () => {
    const measurementSystem = get(screenStore.scoreboard, 'event.measurementSystem')
    if (measurementSystem && measurementSystem === MeasurementSystem.Imperial) {
      return true
    }
    return false
  }

  const getScreenTitle = () => {
    return get(screenStore.scoreboard, 'event.name', null)
  }

  // const getScreenName = () => {
  //   return get(screenStore.scoreboard, 'event.name', null)
  // }

  const handleSetSort = (key) => {
    const isChanged = screenStore.sortField !== key
    screenStore.updateSortField(key)
    if (eventUuid && isChanged) screenStore.getScoreboard(eventUuid)
  }

  const handleLeagueChange = (league) => {
    const isChanged = screenStore.league !== league
    screenStore.updateLeague(league)
    if (eventUuid && isChanged) screenStore.getScoreboard(eventUuid)
  }

  const handleGenderChange = (gender) => {
    const isChanged = screenStore.gender !== gender
    screenStore.updateGender(gender)
    if (eventUuid && isChanged) screenStore.getScoreboard(eventUuid)
  }

  const renderHeader = () => {
    return (
      <div>
        <div className={classes.header}>
          <img src={Logo} className={classes.logo} />
          <div className={classes.flexSpacer} />
          <div className={classes.screenTitle}>{getScreenTitle()}</div>
        </div>
        <div className={classes.filterOuterContainer}>{renderFilters()}</div>
        <div className={classes.subHeader}>
          <div className={classes.subHeaderText}>{t('scoreboard')}</div>
          <div className={classes.flexSpacer} />
          {/* <div className={classes.subHeaderText}>{getScreenName()}</div> */}
        </div>
        <div className={classes.headerBorder} />
      </div>
    )
  }

  const renderScores = () => {
    const scores = get(screenStore.scoreboard, 'topScores') || []

    return scores.map((item, index) => {
      return (
        <ScoreRow
          key={`${item.id}-${index}`}
          rank={index + 1}
          item={item}
          displayHeading={index === 0}
          visibleFields={get(screenStore.scoreboard, 'event.throwData', [])}
          currentSortField={get(screenStore.scoreboard, 'sortField', null)}
          setSort={handleSetSort}
          imperial={getIsImperial()}
          link
        />
      )
    })
  }

  const renderCurrentPlayer = () => {
    const activeThrower = get(screenStore.scoreboard, 'activeThrower')

    const latestScore = get(screenStore.scoreboard, 'latestScore')
    // const latestPartialScore = get(screenStore.scoreboard, 'latestPartialScore')

    let latestScoreData = latestScore

    // PARTIAL THROW NOT SUPPORTED (CURRENTLY)
    // Check if we should use partial score instead of latest score
    // if (isDefined(latestPartialScore?.eventThrowId) && latestPartialScore?.eventThrowId !== latestScore?.eventThrowId) {
    //   latestScoreData = latestPartialScore
    // }

    let item = latestScoreData

    // We have a new player waiting for score?
    const isLatestScoreFromActiveThrower =
      typeof activeThrower === 'object' && latestScore && activeThrower?.id === get(latestScore, 'thrower.id')
    const isSameCustomName = typeof activeThrower === 'string' && activeThrower === get(latestScore, 'thrower')
    const isNoLatestScore = activeThrower && !latestScore
    // if (true )
    if ((!isLatestScoreFromActiveThrower && !isSameCustomName) || isNoLatestScore) {
      item = {
        thrower: activeThrower,
        waiting: true,
      }
    }

    if (!activeThrower) {
      // No active player
      item = null
    }

    if (!item) {
      item = {
        id: null,
      }
    }

    // Key is used here to trigger animation when rendering again
    return (
      <ScoreRow
        key={`${get(item, 'extId', null)}-${get(item, 'thrower.id')}`}
        rank={1}
        item={item}
        visibleFields={get(screenStore.scoreboard, 'event.throwData', [])}
        currentSortField={get(screenStore.scoreboard, 'sortField', null)}
        setSort={handleSetSort}
        imperial={getIsImperial()}
        displayHeading
        star
        link
      />
    )
  }

  const renderTime = () => {
    const timestamp = get(screenStore.scoreboard, 'timestamp')
    if (timestamp) {
      return <div className={classes.currentTime}>{moment(timestamp).format('HH:mm')}</div>
    }
  }

  const renderFilters = () => {
    const supportedLeagues: string[] = get(screenStore.scoreboard, 'event.skillLevel', [])
    const supportedGenders: string[] = get(screenStore.scoreboard, 'event.gender', [])

    return (
      <div className={classes.filterRow}>
        {supportedLeagues?.length > 1 && (
          <div className={classes.filterInner}>
            {supportedLeagues.includes('all') && (
              <div
                className={`${classes.filterLeft} ${screenStore.league === null ? classes.filterBoxActive : ''}`}
                onClick={() => handleLeagueChange(null)}
              >
                <div className={screenStore.league === null ? classes.filterTextActive : classes.filterText}>
                  {t('all_untranslated')}
                </div>
              </div>
            )}
            {supportedLeagues.includes('pro') && (
              <div
                className={`${classes.filterCenter} ${
                  screenStore.league === League.Pro ? classes.filterBoxActive : ''
                }`}
              >
                <div
                  className={screenStore.league === League.Pro ? classes.filterTextActive : classes.filterText}
                  onClick={() => handleLeagueChange(League.Pro)}
                >
                  {t('pro_untranslated')}
                </div>
              </div>
            )}
            {supportedLeagues.includes('amateur') && (
              <div
                className={`${classes.filterRight} ${
                  screenStore.league === League.Amateur ? classes.filterBoxActive : ''
                }`}
              >
                <div
                  className={screenStore.league === League.Amateur ? classes.filterTextActive : classes.filterText}
                  onClick={() => handleLeagueChange(League.Amateur)}
                >
                  {t('amateur_untranslated')}
                </div>
              </div>
            )}
          </div>
        )}

        <div className={classes.filterSpacer} />

        {supportedGenders?.length > 1 && (
          <div className={classes.filterInner}>
            {supportedGenders.includes('all') && (
              <div
                className={`${classes.filterLeft} ${screenStore.gender === null ? classes.filterBoxActive : ''}`}
                onClick={() => handleGenderChange(null)}
              >
                <div className={screenStore.gender === null ? classes.filterTextActive : classes.filterText}>
                  {t('all_untranslated')}
                </div>
              </div>
            )}
            {supportedGenders.includes('male') && (
              <div
                className={`${classes.filterCenter} ${
                  screenStore.gender === Gender.Male ? classes.filterBoxActive : ''
                }`}
              >
                <div
                  className={screenStore.gender === Gender.Male ? classes.filterTextActive : classes.filterText}
                  onClick={() => handleGenderChange(Gender.Male)}
                >
                  {t('male_untranslated')}
                </div>
              </div>
            )}
            {supportedGenders.includes('female') && (
              <div
                className={`${classes.filterRight} ${
                  screenStore.gender === Gender.Female ? classes.filterBoxActive : ''
                }`}
              >
                <div
                  className={screenStore.gender === Gender.Female ? classes.filterTextActive : classes.filterText}
                  onClick={() => handleGenderChange(Gender.Female)}
                >
                  {t('female_untranslated')}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {renderTime()}
      {renderHeader()}
      <div className={classes.content}>
        <div>{renderCurrentPlayer()}</div>
        <div className={classes.divider} />
        {renderScores()}
      </div>
    </div>
  )
}

export default observer(Scoreboard)
