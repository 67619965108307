import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './Translations/en.json'
import fi from './Translations/fi.json'

// Fallback missing fi fields with english fields
const fiFallbacked = {
  ...en,
  ...fi,
}

// Translations
const resources = {
  en: {
    translation: en,
  },
  fi: {
    translation: fiFallbacked,
  },
}

i18n
  // Pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  // Init i18next
  // For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      // Not needed for react as it escapes by default
      escapeValue: false,
    },
  })

export default i18n
