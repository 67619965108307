import apisauce from 'apisauce'

const ApiUrls = {
  Local: 'http://127.0.0.1:1337/v1/scoreboard/',
  Stage: 'https://gameproofer-api-staging.peanutsgroup.com/v1/scoreboard/',
  Production: 'https://gameproofer-api.peanutsgroup.com/v1/scoreboard/',
}

let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

const create = (baseURL = ApiUrl) => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL,
    timeout: 20000,
  })

  return {
    getScoreboard: (eventUuid, sortField, gender, league) =>
      api.get(eventUuid, { sortField, gender, skillLevel: league }),

    getPlayerScores: (eventUuid, playerUuid, sortField) => api.get(`${eventUuid}/${playerUuid}`, { sortField }),
  }
}

const Api = create()

export default Api
