import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '@mui/material/TextField'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '1.5rem',
    position: 'relative',
  },
  containerNoMargin: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  inputRoot: {
    padding: 0,
    width: '100%',
  },
  input: {
    width: '100%',
    height: '3.5rem',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    borderWidth: '1px',
    padding: '0 1rem !important',
    '&:focus': {
      backgroundColor: Colors.white,
    },
  },
  multiline: {
    width: '100%',
    height: '10.5rem',
    minHeight: '10.5rem',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    borderWidth: '1px',
    padding: '1rem 1rem !important',
    '&:focus': {
      backgroundColor: Colors.white,
    },
  },
  notchedOutline: {
    border: `1px solid ${Colors.border}`,
  },
}))

export default function Input(props) {
  const { iconLeft, ...rest } = props

  const classes = useStyles()

  const getContainerClass = () => {
    const classNames = [classes.container]
    if (props.noMargin) classNames.push(classes.containerNoMargin)
    return classNames.join(' ')
  }

  const onChange = (evt) => props.onChange(evt.target.value)

  const renderIconLeft = () => {
    if (props.iconLeft) {
      return <img src={props.iconLeft} className={classes.iconLeft} />
    }
    return null
  }

  return (
    <div className={getContainerClass()}>
      <TextField
        {...rest}
        id={props.id}
        label={props.label || undefined}
        value={props.value || ''}
        onKeyPress={props.handleKeyPress}
        placeholder={props.placeholder}
        InputProps={{
          classes: {
            input: props.multiline ? classes.multiline : classes.input,
            root: classes.inputRoot,
            notchedOutline: classes.notchedOutline,
          },
        }}
        inputProps={{ maxLength: props.maxLength }}
        type={props.type}
        multiline={props.multiline}
        variant='outlined'
        autoFocus={!!props.autoFocus}
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        onChange={onChange}
      />
      {renderIconLeft()}
    </div>
  )
}
