import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './Containers'
import { observer } from 'mobx-react'

const App = () => {
  useEffect(() => {
    // Detect www. in url and redirect without it
    if (window.location.hostname.indexOf('www.') === 0) {
      // @ts-ignore
      window.location = window.location.href.replace('www.', '')
    }
  }, [])

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export default observer(App)
