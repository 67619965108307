import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useStore } from '../Models/RootStore'
import Notification from '../Components/Common/Notification'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

function AppLayout(props) {
  const classes = useStyles()

  const { appStore }: any = useStore()
  const { notification, clearNotification } = appStore

  return (
    <div className={classes.root}>
      {props.children}
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AppLayout)
