import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginLeft: '0.5rem',
    userSelect: 'none',
  },
  rightIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    marginLeft: '0.75rem',
    userSelect: 'none',
  },
  baseButton: {
    backgroundColor: Colors.brandPrimary,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '3rem',
    borderRadius: '0.3125rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.brandPrimary,
    },
  },
  fullWidth: {
    width: '100%',
  },
  baseButtonText: {
    whiteSpace: 'nowrap',
    margin: 0,
    fontSize: '1rem',
    fontWeight: 700,
  },
  // Button variants
  defaultButton: {
    backgroundColor: Colors.brandPrimary,
  },
  defaultButtonText: {
    color: Colors.white,
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.black}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.black10,
    },
  },
  outlinedButtonText: {
    color: Colors.black,
  },
  smallButton: {
    padding: '0.75rem',
    height: '2.75rem',
  },
  smallButtonText: {
    fontSize: '0.75rem',
  },
  disabledButton: {
    opacity: 0.4,
  },
  // Loading icon variants
  colorWhite: {
    color: Colors.white,
  },
  colorBlack: {
    color: Colors.black,
  },
  tallButton: {
    height: '3.125rem',
  },
}))

export default function BaseButton(props) {
  const classes = useStyles()

  const buttonClasses = [classes.baseButton]
  const buttonTextClasses = [classes.baseButtonText]
  let loadingClass = classes.colorWhite
  let loadingSize = 22

  if (props.fullWidth) {
    buttonClasses.push(classes.fullWidth)
  }

  // Add button base style
  if (props.outlined) {
    loadingClass = classes.colorBlack
    buttonClasses.push(classes.outlinedButton)
    buttonTextClasses.push(classes.outlinedButtonText)
  } else {
    buttonClasses.push(classes.defaultButton)
    buttonTextClasses.push(classes.defaultButtonText)
  }

  // Add size
  if (props.small) {
    loadingSize = 18
    buttonClasses.push(classes.smallButton)
    buttonTextClasses.push(classes.smallButtonText)
  }

  if (props.tall) {
    buttonClasses.push(classes.tallButton)
  }

  if (props.buttonStyle) buttonClasses.push(props.buttonStyle)
  if (props.buttonTextStyle) buttonTextClasses.push(props.buttonTextStyle)

  const renderContent = () => {
    if (props.loading) return <CircularProgress className={loadingClass} size={loadingSize} />

    return (
      <>
        {props.leftIcon && <img src={props.leftIcon} className={classes.leftIcon} alt='icon' />}
        <p className={buttonTextClasses.join(' ')}>{props.text}</p>
        {props.rightIcon && <img src={props.rightIcon} className={classes.rightIcon} alt='icon' />}
      </>
    )
  }

  return (
    <Button
      className={buttonClasses.join(' ')}
      onClick={props.onClick}
      disabled={props.disabled}
      classes={{ disabled: classes.disabledButton }}
    >
      {renderContent()}
    </Button>
  )
}
