import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppLayout } from '../Layouts'

// Public Routes
import Scoreboard from './Scoreboard'
import Profile from './Profile'

export default function AppRoutes(props) {
  return (
    <AppLayout>
      <Routes>
        <Route path='/scoreboard/:eventUuid' element={<Scoreboard />} />
        <Route path='/scoreboard/:eventUuid/profile/:playerUuid' element={<Profile />} />
      </Routes>
    </AppLayout>
  )
}
