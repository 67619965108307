import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import ScreenStore from './ScreenStore'

export class RootStore {
  appStore: AppStore
  screenStore: ScreenStore

  constructor() {
    this.appStore = new AppStore(this)
    this.screenStore = new ScreenStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext<RootStore>({} as RootStore)
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext<RootStore>(StoreContext)
